import { Component, OnInit } from '@angular/core';
import { AppUtilService } from '../../services/app-util.service';
import { Router } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  sectionList: any[];
  articleDetail: any;
  isClick: boolean;

  constructor(private appUtilSerive: AppUtilService, private router: Router) {
    this.sectionList = [];
    this.articleDetail = null;
    this.isClick = false;
  }

  ngOnInit() {
    this.appUtilSerive.getSectionList().subscribe(response => {
      // this.sectionList = response.sections;
      response.sections.forEach((ele, index) => {
        if (ele.name.toLowerCase() === 'get in touch!') {
          this.appUtilSerive.getArticleListById(ele.id).subscribe(response => {
            this.articleDetail = response.articles[0];
          }, error => { });
        } else {
          this.sectionList.push(response.sections[index]);
        }
      });
    }, error => { });
  }

  watchArticles(id: any) {
    this.router.navigateByUrl('/help/faq/' + id);
  }

  checkClick() {
    if (!this.isClick) {
      this.isClick = true;
    }
  }
}
