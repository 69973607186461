import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-quality',
  templateUrl: './video-quality.component.html',
  styleUrls: ['./video-quality.component.scss']
})
export class VideoQualityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
