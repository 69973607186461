import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-upload-form',
  templateUrl: './video-upload-form.component.html',
  styleUrls: ['./video-upload-form.component.scss']
})
export class VideoUploadFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
