import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-live-shell',
  templateUrl: './live-shell.component.html',
  styleUrls: ['../default-shell/default-shell.component.scss']
})
export class LiveShellComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
