import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../../../environments/environment';
import { AppUtilService } from '../../../shared/services/app-util.service';

@Injectable({
    providedIn: 'root'
})

export class OmniService {
    ottApiUrl: string;
    isBrowser: any;
    serverURL: any;
    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private httpClient: HttpClient,
        private appUtilService: AppUtilService
    ) {
        this.ottApiUrl = environment.OTT_API_URL
        this.isBrowser = isPlatformBrowser(platformId);
        this.serverURL = environment.SERVER_URL
    }


    GetActiveSubscriptions(): Observable<HttpResponse<any>> {
        return this.httpClient.get(`${this.ottApiUrl}AppSubscriptionManagement/GetActiveSubscriptions`).pipe((res: Observable<HttpResponse<any>>) => {
            return res;
        });
    }
    getWatchoActiveSubscription(): Observable<HttpResponse<any>> {
        return this.httpClient.get(`${this.ottApiUrl}SubscriptionManagement/GetActiveSubscriptions`).pipe((res: Observable<HttpResponse<any>>) => {
            return res;
        });
    }
    getWatchoSubscription(token, OttSubscriberID): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.get(`${this.ottApiUrl}SubscriptionManagement/GetActiveSubscriptions/${OttSubscriberID}`, { headers: headers }).pipe((res: Observable<HttpResponse<any>>) => {
            return res;
        });
    }
    getPrepaidBalance(OTTSubscriberID, token): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.get(`${this.ottApiUrl}SubscriptionManagement/GetPrepaidBalance/${OTTSubscriberID}`, { headers: headers }).pipe((res: Observable<HttpResponse<any>>) => {
            return res;
        });
    }
    GetDishD2hActiveSubscriptions(token, OttSubscriberID): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.get(`${this.ottApiUrl}AppSubscriptionManagement/GetActiveSubscriptions/${OttSubscriberID}`, { headers: headers }).pipe((res: Observable<HttpResponse<any>>) => {
            return res;
        });
    }

    generateTransactionforOTT(command) {
        var headers = new HttpHeaders().set('Authorization', this.appUtilService.getAuthKey());
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}AppSubscriptionManagement/SubscriptionRequestWithPayment`, command, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }
    generateTransactionforOTTV1(command) {
        var headers = new HttpHeaders().set('Authorization', this.appUtilService.getAuthKey());
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}AppSubscriptionManagement/V1/SubscriptionRequestWithPayment`, command, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    getOTTPlansSubscriptionHistory(OttSubscriberID) {
        var headers = new HttpHeaders().set('Authorization', this.appUtilService.getAuthKey());
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.get(`${this.ottApiUrl}AppSubscriptionManagement/SubscriptionHistory/${OttSubscriberID}`, { headers: headers }).pipe((res: Observable<HttpResponse<any>>) => {
            return res;
        });
    }

    SubscriptionRequestWithPrepaidBalance(command) {
        var headers = new HttpHeaders().set('Authorization', this.appUtilService.getAuthKey());
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}AppSubscriptionManagement/SubscriptionRequestWithPrepaidBalance`, command, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    ValidateCouponCodeForSubscriptionPlan(OTTSubscriberID, CoupenCode, subscribedPlan) {
        var headers = new HttpHeaders().set('Authorization', this.appUtilService.getAuthKey());
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.get(`${this.ottApiUrl}AppSubscriptionManagement/ValidateCouponCodeForSubscriptionPlan/${OTTSubscriberID}/${CoupenCode}/${subscribedPlan}`, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }
    UpdatePaymentStatus(command) {
        var headers = new HttpHeaders().set('Authorization', this.appUtilService.getAuthKey());
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}AppSubscriptionManagement/UpdatePaymentStatus`, command, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    UpdatePaymentStatusV1(command) {
        var headers = new HttpHeaders().set('Authorization', this.appUtilService.getAuthKey());
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}AppSubscriptionManagement/V1/UpdatePaymentStatus`, command, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    initateCancelSubscriptionRequest(payload) {
        var headers = new HttpHeaders().set('Authorization', this.appUtilService.getAuthKey());
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}AppSubscriptionManagement/InitateCancelSubscriptionRequest`, payload, { headers: headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    GenerateMobileOTP(userNumber) {
        var headers = new HttpHeaders().set('Authorization', this.appUtilService.getAuthKey());
        headers = headers.append('Content-Type', 'application/json');
        let URL = `${this.ottApiUrl}UserManagement/GenerateOTP/${userNumber}/${userNumber}`
        return this.httpClient.get(URL, { headers: headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }


    ValidateMobileOTP(userNumber, userOTP) {
        var headers = new HttpHeaders().set('Authorization', this.appUtilService.getAuthKey());
        headers = headers.append('Content-Type', 'application/json');
        let URL = `${this.ottApiUrl}UserManagement/ValidateOTP/${userNumber}/${userOTP}`
        return this.httpClient.get(URL, { headers: headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    updateMobileNumber(payload) {
        var headers = new HttpHeaders().set('Authorization', this.appUtilService.getAuthKey());
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}UserManagement/UpdateUserProfile`, payload, { headers: headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    cancelSubscriptionRequest(payload) {
        var headers = new HttpHeaders().set('Authorization', this.appUtilService.getAuthKey());
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}AppSubscriptionManagement/CancelSubscriptionRequest`, payload, { headers: headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    getSonyLivToken(Pseudocode) {
        let URL = `https://tomcat4sony.mysmartstick.com/ServletSample/ServletFirstClass?Pseudocode=${Pseudocode}`
        return this.httpClient.get(URL)
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    GetDomainRedirectURL() {
        let URL = `${this.serverURL}API/GetDomainRedirectURL`
        return this.httpClient.get(URL)
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    getIPAddress() {
        return this.httpClient.get("https://api.ipify.org/?format=json");
    }

    getZeeToken(userNumber) {
        let URL = `${this.serverURL}API/getZeeToken?mobileNumber=${userNumber}`
        return this.httpClient.get(URL)
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    getChaupalToken(userNumber, timeStamp) {
        let URL = `${this.serverURL}API/getChaupalToken?userId=${userNumber}&timeStamp=${timeStamp}`
        return this.httpClient.get(URL)
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    getPaymChecksumToken(payload) {
        // let URL = `http://localhost:8080/API/getPaytmJsCheckOut`
        let URL = `${this.serverURL}API/getPaytmJsCheckOut`
        return this.httpClient.post(URL, payload)
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    getPaytmPaymentStatus(OrderId) {
        // let URL = `http://localhost:8080/API/getPaytmPaymentStatus?OrderId=${OrderId}`
        let URL = `${this.serverURL}API/getPaytmPaymentStatus?OrderId=${OrderId}`
        return this.httpClient.get(URL)
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }
    createZeeSubscription(payload) {
        let URL = `${this.serverURL}API/createZeeSubscription`
        return this.httpClient.post(URL, payload)
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    ValidateKlikk(payload) {
        let URL = `${this.serverURL}API/getKlikkRedirectURL`
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.httpClient.post(URL, payload, { headers: headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }
    getUA = () => {
        let device = "Unknown";
        const ua = {
            "Generic Linux": /Linux/i,
            "Android": /Android/i,
            "BlackBerry": /BlackBerry/i,
            "Bluebird": /EF500/i,
            "Chrome OS": /CrOS/i,
            "Datalogic": /DL-AXIS/i,
            "Honeywell": /CT50/i,
            "iPad": /iPad/i,
            "iPhone": /iPhone/i,
            "iPod": /iPod/i,
            "macOS": /Macintosh/i,
            "Windows": /IEMobile|Windows/i,
            "Zebra": /TC70|TC55/i,
        }
        Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
        return device;
    }

    userOfferEligibility(OTTSubscriberID) {
        var headers = new HttpHeaders().set('Authorization', this.appUtilService.getAuthKey());
        headers = headers.append('Content-Type', 'application/json');
        let URL = `${this.ottApiUrl}AppSubscriptionManagement/SubscriptionOfferDetails/${OTTSubscriberID}`
        return this.httpClient.post(URL, '', { headers: headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    userOfferEligibilityWithSubscriptionPlanID(OTTSubscriberID, SubscriptionPlanID) {
        var headers = new HttpHeaders().set('Authorization', this.appUtilService.getAuthKey());
        headers = headers.append('Content-Type', 'application/json');
        let URL = `${this.ottApiUrl}AppSubscriptionManagement/SubscriptionOfferDetails/${OTTSubscriberID}/${SubscriptionPlanID}`
        return this.httpClient.post(URL, '', { headers: headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }
}